import SideNav from "./SideNav"
import {Route, Switch} from "react-router-dom"
import Routes from "../routes"
import LinkAccountsPage from "../pages/LinkAccountsPage"
import React from "react"
import HomePage from "../pages/HomePage"
import {makeStyles, Theme} from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    flex: 1,
    padding: theme.spacing(1),
  },
}))

export default function App(): JSX.Element {
  const classes = useStyles()
  return (
    <>
      <SideNav/>
      <main className={classes.main}>
        <Switch>
          <Route exact path={Routes.home}>
            <HomePage/>
          </Route>
          <Route exact path={Routes.linkAccounts}>
            <LinkAccountsPage/>
          </Route>
          <Route exact path={Routes.broadcasterSetup}>

          </Route>
          <Route exact path={Routes.broadcasterPlayers}>

          </Route>
          <Route exact path={Routes.broadcasterActions}>

          </Route>
        </Switch>
      </main>
    </>
  )
}
