// https://hashrocket.com/blog/posts/managing-react-router-pathnames

export default class Routes {
  static readonly home = "/"
  static readonly linkAccounts = "/link-accounts"
  static readonly broadcaster = "/broadcaster"
  static readonly broadcasterSetup = Routes.broadcaster + "/setup"
  static readonly broadcasterPlayers = Routes.broadcaster + "/players"
  static readonly broadcasterActions = Routes.broadcaster + "/actions"
}
