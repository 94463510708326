import {Button, withStyles} from "@material-ui/core"
import {purple} from "@material-ui/core/colors"

export default withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[700]),
    backgroundColor: purple[700],
    "&:hover": {
      backgroundColor: purple[800],
    },
  },
}))(Button)
