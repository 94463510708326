import {MinecraftPlayer} from "./playerdb"

export const avatarSkin = (minecraftPlayer: MinecraftPlayer): string => {
  return `https://mc-heads.net/avatar/${minecraftPlayer.id}`
}

export const fullBodySkin = (minecraftPlayer: MinecraftPlayer): string => {
  return `https://mc-heads.net/player/${minecraftPlayer.id}`
}

export const isometricSkin = (minecraftPlayer: MinecraftPlayer, direction: "left" | "right", height = 180): string => {
  return `https://mc-heads.net/body/${minecraftPlayer.id}/${height}/${direction}`
}
