import React, {ChangeEvent, useEffect, useState} from "react"
import {Box, Button, Card, CardContent, Grid, Link, makeStyles, TextField, Typography} from "@material-ui/core"
import {getPlayer, isValidUsername, isValidUuid, MinecraftPlayer} from "../services/playerdb"
import {isometricSkin} from "../services/mc-heads"
import {saveMinecraftUuid} from "../services/api"
import {User} from "../models/User"

const useStyles = makeStyles(() => ({
  name: {
    fontSize: "1.1rem",
  },
  uuid: {
    fontStyle: "italic",
    fontSize: ".65rem",
  },
}))

const dummy: MinecraftPlayer = {
  username: "Steve",
  id: "c06f8906-4c8a-4911-9c29-ea1dbd1aab82",
}


type Props = {
  loggedIn: User | false | null
  stepNext: () => void
  stepBack: () => void
}

export function LinkAccountsStepMinecraft(props: Props): JSX.Element {
  const classes = useStyles()

  const defaultValue = (props.loggedIn && props.loggedIn?.minecraft !== undefined) ? props.loggedIn.minecraft : ""

  // <TextField>
  const [value, setValue] = useState<string>(defaultValue)
  const [player, setPlayer] = useState<MinecraftPlayer | null>()
  const [error, setError] = useState<string>("")

  const handlePlayerNameInputField = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValue(event.target.value)
  }
  // </TextField>

  // <NextButton>
  const [saving, setSaving] = useState<boolean>(false)

  const onNext = () => {
    if (player == null) return

    setSaving(true)

    saveMinecraftUuid(player as MinecraftPlayer)
      .then(() => props.stepNext())
      .catch(reason => {
        console.log("save minecraft uuid", reason)
      })
  }
  // </NextButton>

  useEffect(() => {
    const timeOutId = setTimeout(async () => {
      if (value === "") {
        setError("")
      } else if (!isValidUsername(value) && !isValidUuid(value)) {
        setError("Not a valid username or UUID")
      } else if (isValidUsername(value) || isValidUuid(value)) {
        setError("")
      }

      getPlayer(value)
        .then(player => setPlayer(player))
        .catch(reason => {
          console.log(reason)
          setPlayer(null)
          setError("Could not fetch data, e.g. wrong username")
        })
    }, 500)
    return () => clearTimeout(timeOutId)
  }, [value])

  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <Typography paragraph>
          Enter your Minecraft username or UUID.
        </Typography>
        <Box height={70}>
          <TextField required
                     fullWidth
                     value={value}
                     onChange={handlePlayerNameInputField}
                     label="Minecraft username or UUID"
                     placeholder={`Example: ${dummy?.username} or ${dummy?.id}`}
                     error={error !== ""}
                     helperText={error}
          />
        </Box>
        <Typography paragraph>
          The field is validated against specific formats and is case insensitive.
        </Typography>
        <Typography paragraph>
          For <b>usernames</b> the allowed characters are a-z, A-Z, 0-9 and underscore (_). The length are set between
          3
          and 16 characters (inclusive).{" "}
          <Link href="https://help.minecraft.net/hc/en-us/articles/360034636712" target="_blank">Source</Link>
        </Typography>
        <Typography paragraph>
          And for <b>UUID</b>s, it must be a UUID v4 string with or without dashes (-).
        </Typography>
        <Button
          variant="contained"
          color={"secondary"}
          onClick={props.stepBack}
        >
          Back
        </Button>
        {" "}
        <Box mx={1} display={"inline-block"}>
          <Button
            variant="contained"
            color={"primary"}
            onClick={onNext}
            disabled={player === null || saving}
          >
            Next
          </Button>
        </Box>
        <Box display={saving ? "inline-block" : "none"}>
          <Typography>
            Saving...
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Card variant={"outlined"}>
          <CardContent>
            <Box textAlign={"center"} height={255}>
              <Typography variant="h6">
                {player?.username ?? dummy?.username}
              </Typography>
              <img src={isometricSkin(player ?? dummy, "left", 85)} alt={""}/>
              <Typography className={classes.uuid}>
                {player?.id ?? dummy?.id}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
