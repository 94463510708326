import {User} from "../models/User"
import {MinecraftPlayer} from "./playerdb"

export async function getTwitchUser(): Promise<User> {
  const response = await fetch("/api/user", {
    headers: {
      "Accept": "application/json",
    },
  })

  const data = await response.json()

  if ("error" in data) {
    throw new Error(data.message)
  }

  return data as User
}

export async function saveMinecraftUuid(player: MinecraftPlayer): Promise<boolean> {
  const response = await fetch("/api/user/minecraft", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      "uuid": player.id,
    }),
  })

  if (!response.ok) {
    throw new Error("Failed when saving to server")
  }

  return response.ok
}
