import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import {CssBaseline, MuiThemeProvider} from "@material-ui/core"
import {Helmet} from "react-helmet"
import {BrowserRouter} from "react-router-dom"
import App from "./components/App"
import theme from "./theme"

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <Helmet>
        <meta name="theme-color" content={theme.palette.primary.main} />
      </Helmet>
      <CssBaseline>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </CssBaseline>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById("root"),
)
