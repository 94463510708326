export interface MinecraftPlayer {
  username: string
  /**
   * Minecraft UUID with dashes
   */
  id: string
}

export async function getPlayer(player: string): Promise<MinecraftPlayer | null> {
  if (!(isValidUsername(player) || isValidUuid(player))) {
    return null
  }

  if (isValidUuid(player)) {
    player = player.replaceAll("-", "")
  }

  const response = await fetch(`https://playerdb.co/api/player/minecraft/${player}`, {
    headers: {
      "Accept": "application/json",
    },
  })
  const json = await response.json()

  if (!json.success) {
    throw new Error("Could not find " + player)
  }

  return {
    username: json.data.player.username,
    id: json.data.player.id,
  }
}

// https://help.minecraft.net/hc/en-us/articles/360034636712-Minecraft-Usernames
export function isValidUsername(username: string): boolean {
  const regex = /^[a-z0-9_]{3,16}$/i

  return regex.test(username)
}

// https://minecraft-de.gamepedia.com/UUID
export function isValidUuid(uuid: string): boolean {
  const regex = /^[0-9a-f]{8}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{12}$/i

  return regex.test(uuid)
}
