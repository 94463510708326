import React, {useEffect, useState} from "react"
import {Grid, Step, StepButton, StepContent, Stepper, Typography} from "@material-ui/core"
import {LinkAccountsStepTwitch} from "../components/LinkAccountsStepTwitch"
import {LinkAccountsStepMinecraft} from "../components/LinkAccountsStepMinecraft"
import {User} from "../models/User"
import {getTwitchUser} from "../services/api"

export default function LinkAccountsPage(): JSX.Element {
  const [loggedIn, setLoggedIn] = useState<User | false | null>(null)
  const [step, setStep] = useState(0)
  const steps = [0, 2]

  useEffect(() => {
    getTwitchUser()
      .then(twitchUser => setLoggedIn(twitchUser))
      .catch(reason => {
        console.log("get twitch user", reason)
        setLoggedIn(false)
      })
  }, [step])

  const stepNext = () => {
    const newStep = step + 1
    if (newStep <= steps[1]) {
      setStep(newStep)
    }
  }
  const stepBack = () => {
    const newStep = step - 1
    if (newStep >= steps[0]) {
      setStep(newStep)
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={2}/>
      <Grid item xs>
        <Stepper activeStep={step} orientation="vertical" elevation={2}>
          <Step key={0}>
            <StepButton onClick={() => setStep(0)} disabled={process.env.NODE_ENV === "production"}>
              Login with Twitch
            </StepButton>
            <StepContent>
              <LinkAccountsStepTwitch loggedIn={loggedIn} stepNext={stepNext}/>
            </StepContent>
          </Step>
          <Step key={1}>
            <StepButton onClick={() => setStep(1)} disabled={process.env.NODE_ENV === "production"}>
              Minecraft account
            </StepButton>
            <StepContent>
              <LinkAccountsStepMinecraft loggedIn={loggedIn} stepNext={stepNext} stepBack={stepBack}/>
            </StepContent>
          </Step>
          <Step key={2}>
            <StepButton onClick={() => setStep(2)} disabled={process.env.NODE_ENV === "production"}>
              Synchronization
            </StepButton>
            <StepContent>
              <Typography paragraph>
                Great! Your Minecraft account has now been linked to your Twitch account!
              </Typography>
              <Typography paragraph>
                Now you will have to wait for the Minecraft server to synchronize the new data,
                which can take up to ten minutes.
              </Typography>
            </StepContent>
          </Step>
        </Stepper>
      </Grid>
      <Grid item xs={2}/>
    </Grid>
  )
}


